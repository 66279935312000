import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Headlines from "../components/headlines"
import Impacto from "../components/impacto"
import VideoCorporativo from "../components/videoCorporativo"
import Plataformas from "../components/plataformas"
import Proyectos from "../components/proyectos"
import Clientes from "../components/clientes"
import Testimonios from "../components/testimonios"
import Alianzas from "../components/alianzas"
import PrensaShort from "../components/prensaShort"
import Contactanos from "../components/contactanos"
import ExtraContact from "../components/extraContact"
import Helmet from "react-helmet"

const PageIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
    //const posts = data.allMarkdownRemark.edges

    return (
        <Layout location={location} title={siteTitle}>
            <Helmet>
                <title>{siteTitle}</title>
            </Helmet>
            <section id="inicio">
                <Headlines />
            </section>
            <section id="impacto">
                <Impacto />
            </section>
            <VideoCorporativo />
            <section id="plataformas">
                <Plataformas />
            </section>
            <Proyectos />
            <section id="clientes">
                <Clientes />
            </section>
            <Testimonios />
            <Alianzas />
            <PrensaShort />
            <Contactanos />
            <ExtraContact />
        </Layout>
    )
}

export default PageIndex

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`
