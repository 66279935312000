import React from "react"
import InnerContainer from "./innerContainer"
import ListaPrensa from "./listaPrensa"
import { Link } from "gatsby"

const PrensaShort = () => {
    return (
        <div style={{ backgroundColor: "#f2f2f2", paddingBottom: "3rem" }}>
            <InnerContainer>
                <h1 style={{ textAlign: "center", marginBottom: "80px" }}>Prensa</h1>
                <ListaPrensa limit={6} />
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "2rem" }}>
                    <Link className={"styled-link"} to="/prensa/">
                        Ver todas
                    </Link>
                </div>
            </InnerContainer>
        </div>
    )
}

export default PrensaShort
