import React from "react"
import TrabajaConNosotros from "../components/trabajaConNosotros"
import Newsletter from "../components/newsletter"
import styles from "./extraContact.module.css"

const ExtraContact = () => {
    return (
        <div className={styles.container}>
            <TrabajaConNosotros />
            <Newsletter />
        </div>
    )
}

export default ExtraContact
