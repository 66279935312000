import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InnerContainer from "./innerContainer"
import Img from "gatsby-image"

const Clientes = () => {
    const data = useStaticQuery(graphql`
        query ClientsQuery {
            allMarkdownRemark(
                filter: { fields: { sourceInstanceName: { eq: "clients" } } }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                edges {
                    node {
                        frontmatter {
                            title
                            logo {
                                childImageSharp {
                                    fixed(width: 150, quality: 100) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ornamento: file(absolutePath: { regex: "/ornamento1.png/" }) {
                childImageSharp {
                    fixed(width: 150, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div
            style={{
                backgroundImage: `url(${data.ornamento.childImageSharp.fixed.src})`,
                backgroundRepeat: "no-repeat"
            }}
        >
            <InnerContainer>
                <h1 style={{ textAlign: "center" }}>
                    Clientes que han confiado en
                    <br />
                    <em>Open Green Road</em>
                </h1>

                <div
                    style={{
                        marginTop: "80px",
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                        gridGap: "40px 20px",
                        alignItems: "center",
                        justifyItems: "center"
                    }}
                >
                    {data.allMarkdownRemark.edges.map((client, index) => {
                        return (
                            <Img
                                key={index}
                                fixed={client.node.frontmatter.logo.childImageSharp.fixed}
                                style={{ maxHeight: "80px" }}
                                imgStyle={{
                                    objectFit: "contain",
                                    objectPosition: "bottom"
                                }}
                                alt={client.node.frontmatter.title}
                                title={client.node.frontmatter.title}
                            />
                        )
                    })}
                </div>
            </InnerContainer>
        </div>
    )
}

export default Clientes
