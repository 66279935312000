import React from "react"
import InnerContainer from "./innerContainer"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import Slider from "react-slick"
import CustomLeftArrow from "./customLeftArrow"
import CustomRightArrow from "./customRightArrow"

const SliderWrap = styled.div`
    max-width: 900px;
    margin: 0 auto;
    margin-top: 80px;
    padding: 0 30px;
    @media screen and (max-width: 500px) {
        padding: 0 0px;
    }
`
const Proyecto = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 30px;
    flex: 1 1 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-bottom: solid 12px #74992c;
    height: 100%;
`
const Descripcion = styled.div`
    margin: 20px 0;
    font-size: 0.9rem;
    font-weight: 300;
`
const ProyectoBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    align-self: flex-end;
    padding-top: 20px;
    width: 100%;
`

const settings = {
    dots: false,
    centerMode: true,
    centerPadding: "150px",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                centerPadding: "50px",
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                dots: true,
                arrows: false,
                centerPadding: "0px",
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const Proyectos = () => {
    const data = useStaticQuery(graphql`
        query ProjectsQuery {
            allMarkdownRemark(
                filter: { fields: { sourceInstanceName: { eq: "projects" } } }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                edges {
                    node {
                        html
                        frontmatter {
                            title
                            link
                            country
                        }
                    }
                }
            }
            chile: file(absolutePath: { regex: "/CHILE.png/" }) {
                childImageSharp {
                    fixed(width: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            colombia: file(absolutePath: { regex: "/COLOMBIA.png/" }) {
                childImageSharp {
                    fixed(width: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            mexico: file(absolutePath: { regex: "/MEXICO.png/" }) {
                childImageSharp {
                    fixed(width: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const reorderedEdges = data.allMarkdownRemark.edges.sort(
        (p1, p2) => p1.node.frontmatter.order - p2.node.frontmatter.order
    )

    return (
        <div style={{ backgroundColor: "#f2f2f2" }}>
            <InnerContainer>
                <h1 style={{ textAlign: "center" }}>Otros proyectos</h1>
                <SliderWrap className={"proyectos-carousel"}>
                    <Slider {...settings}>
                        {reorderedEdges.map((project, index) => {
                            let flags = []
                            if (project.node.frontmatter.country.includes("chile"))
                                flags.push(
                                    <Image
                                        key={`chile`}
                                        style={{ marginRight: "8px" }}
                                        fixed={data.chile.childImageSharp.fixed}
                                    />
                                )
                            if (project.node.frontmatter.country.includes("colombia"))
                                flags.push(
                                    <Image
                                        key={`colombia`}
                                        style={{ marginRight: "8px" }}
                                        fixed={data.colombia.childImageSharp.fixed}
                                    />
                                )
                            if (project.node.frontmatter.country.includes("mexico"))
                                flags.push(
                                    <Image
                                        key={`mexico`}
                                        style={{ marginRight: "8px" }}
                                        fixed={data.mexico.childImageSharp.fixed}
                                    />
                                )

                            return (
                                <div key={index}>
                                    <Proyecto>
                                        <h2>{project.node.frontmatter.title}</h2>
                                        <Descripcion dangerouslySetInnerHTML={{ __html: project.node.html }} />
                                        <ProyectoBottom>
                                            <span>{flags}</span>
                                            {/* <a
                                                className={"styled-link"}
                                                target="_blank"
                                                rel="noreferrer"
                                                href={project.node.frontmatter.link}
                                            >
                                                Visita el sitio
                                            </a> */}
                                        </ProyectoBottom>
                                    </Proyecto>
                                </div>
                            )
                        })}
                    </Slider>
                </SliderWrap>
            </InnerContainer>
        </div>
    )
}

export default Proyectos
