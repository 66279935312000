import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InnerContainer from "./innerContainer"
import Img from "gatsby-image"
import styled from "styled-components"

const AlianzasContainer = styled.div`
    display: flex;
    margin: 0 auto;
    margin-top: 80px;
    align-items: center;
    justify-content: space-between;
    max-width: 600px;
    flex-wrap: wrap;
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
`
const AlianzasGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 40px 20px;
    align-items: center;
    justify-items: center;
    flex-grow: 3;
    max-width: 400px;
    @media screen and (max-width: 700px) {
        max-width: 100%;
    }
`
const PartnerContainer = styled.div`
    margin-left: 20px;
    @media screen and (max-width: 700px) {
        margin-left: 0;
        margin-top: 30px;
    }
`

const Partner = styled.p`
    font-size: 1.8rem;
    color: #74992c;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    text-align: center;
`

const Alianzas = () => {
    const data = useStaticQuery(graphql`
        query AlliancesQuery {
            allMarkdownRemark(
                filter: { fields: { sourceInstanceName: { eq: "alliances" } } }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                edges {
                    node {
                        frontmatter {
                            title
                            logo {
                                childImageSharp {
                                    fixed(width: 150, quality: 100) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
            partner: file(absolutePath: { regex: "/alliances/FIS-Ameris-PNG.png/" }) {
                childImageSharp {
                    fixed(width: 100, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            ornamento: file(absolutePath: { regex: "/ornamento2.png/" }) {
                childImageSharp {
                    fixed(width: 150, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div
            style={{
                backgroundImage: `url(${data.ornamento.childImageSharp.fixed.src})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom right"
            }}
        >
            <InnerContainer>
                <h1 style={{ textAlign: "center" }}>Nuestras Alianzas</h1>
                <AlianzasContainer>
                    <AlianzasGrid>
                        {data.allMarkdownRemark.edges.map((alliance, index) => {
                            return (
                                <Img
                                    key={index}
                                    fixed={alliance.node.frontmatter.logo.childImageSharp.fixed}
                                    style={{ maxHeight: "80px" }}
                                    imgStyle={{
                                        objectFit: "contain",
                                        objectPosition: "bottom"
                                    }}
                                    alt={alliance.node.frontmatter.title}
                                    title={alliance.node.frontmatter.title}
                                />
                            )
                        })}
                    </AlianzasGrid>
                    <PartnerContainer>
                        <Img
                            fixed={data.partner.childImageSharp.fixed}
                            style={{ maxHeight: "80px" }}
                            imgStyle={{
                                objectFit: "contain",
                                objectPosition: "bottom"
                            }}
                        />
                        <Partner>Partner</Partner>
                    </PartnerContainer>
                </AlianzasContainer>
            </InnerContainer>
        </div>
    )
}

export default Alianzas
