import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InnerContainer from "./innerContainer"
import styled from "styled-components"

const Cifras = styled.div`
    display: flex;
    padding-bottom: 50px;
    justify-content: space-between;
    margin: -10px;
    flex-wrap: wrap;
`
const Cifra = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin: 10px;
`
const Valor = styled.p`
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0px;
`
const Deco = styled.em`
    font-style: normal;
    color: #8fbf26;
`
const Subtitulo = styled.p`
    margin-bottom: 0px;
`

const Impacto = () => {
    const data = useStaticQuery(graphql`
        query ImpactoQuery {
            background: file(absolutePath: { regex: "/fondo_impacto.png/" }) {
                childImageSharp {
                    fluid(maxWidth: 1800, quality: 100) {
                        src
                    }
                }
            }
        }
    `)

    return (
        <div
            style={{
                backgroundImage: `url(${data.background.childImageSharp.fluid.src})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                textAlign: "center",
                color: "white"
            }}
        >
            <InnerContainer>
                <h1 className="light">Nuestro impacto</h1>
                <Cifras>
                    <Cifra>
                        <Valor>
                            11<Deco>+</Deco>
                        </Valor>
                        <Subtitulo>Años de experiencia</Subtitulo>
                    </Cifra>
                    <Cifra>
                        <Valor>4</Valor>
                        <Subtitulo>Países</Subtitulo>
                    </Cifra>
                    <Cifra>
                        <Valor>
                            2.000.000<Deco>+</Deco>
                        </Valor>
                        <Subtitulo>Estudiantes</Subtitulo>
                    </Cifra>
                    <Cifra>
                        <Valor>
                            70.000<Deco>+</Deco>
                        </Valor>
                        <Subtitulo>Docentes</Subtitulo>
                    </Cifra>
                    <Cifra>
                        <Valor>
                            750<Deco>+</Deco>
                        </Valor>
                        <Subtitulo>Establecimientos educacionales</Subtitulo>
                    </Cifra>
                </Cifras>
            </InnerContainer>
        </div>
    )
}

export default Impacto
