import React from "react"
import InnerContainer from "./innerContainer"
import styles from "./plataformas.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Img from "gatsby-image"

const Plataformas = () => {
    const data = useStaticQuery(graphql`
        query PlatformsQuery {
            allMarkdownRemark(
                filter: { fields: { sourceInstanceName: { eq: "platforms" } } }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                edges {
                    node {
                        frontmatter {
                            platform
                            description
                            link
                            flags
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 1000, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            logo {
                                childImageSharp {
                                    fixed(width: 270, quality: 100) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
            chile: file(absolutePath: { regex: "/CHILE.png/" }) {
                childImageSharp {
                    fixed(width: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            colombia: file(absolutePath: { regex: "/COLOMBIA.png/" }) {
                childImageSharp {
                    fixed(width: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            mexico: file(absolutePath: { regex: "/MEXICO.png/" }) {
                childImageSharp {
                    fixed(width: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.plataformas}>
            <InnerContainer>
                <h1>Plataformas</h1>
                <p className={styles.subtitulo}>
                    Conoce sobre nuestros proyectos tecnológicos innovadores, que apoyados por un excelente equipo
                    académico, brindan oportunidades de desarrollo y soporte a toda la comunidad educativa.
                </p>
            </InnerContainer>

            {data.allMarkdownRemark.edges.map((platform, index) => {
                let flags = []
                if (platform.node.frontmatter.flags.includes("chile"))
                    flags.push(
                        <Image key={`chile`} style={{ marginRight: "8px" }} fixed={data.chile.childImageSharp.fixed} />
                    )
                if (platform.node.frontmatter.flags.includes("colombia"))
                    flags.push(
                        <Image
                            key={`colombia`}
                            style={{ marginRight: "8px" }}
                            fixed={data.colombia.childImageSharp.fixed}
                        />
                    )
                if (platform.node.frontmatter.flags.includes("mexico"))
                    flags.push(
                        <Image
                            key={`mexico`}
                            style={{ marginRight: "8px" }}
                            fixed={data.mexico.childImageSharp.fixed}
                        />
                    )

                return (
                    <div key={index} className={styles.plataforma}>
                        <InnerContainer>
                            <div className={styles.wrap}>
                                <div className={styles.content}>
                                    <Image
                                        fixed={platform.node.frontmatter.logo.childImageSharp.fixed}
                                        style={{
                                            maxWidth: "100%",
                                            marginBottom: "20px"
                                        }}
                                        imgStyle={{
                                            width: `100%`,
                                            height: `auto`
                                        }}
                                    />
                                    <p>{platform.node.frontmatter.description}</p>
                                    <div className={styles.bottomInfo}>
                                        <div className={styles.flags}>{flags}</div>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className={"styled-link"}
                                            href={platform.node.frontmatter.link}
                                        >
                                            Visita el sitio
                                        </a>
                                    </div>
                                </div>
                                <div className={styles.image}>
                                    <Img
                                        fluid={platform.node.frontmatter.image.childImageSharp.fluid}
                                        aspectRatio={"4 / 3"}
                                        style={{
                                            boxShadow: "0 0 16px rgba(0, 0, 0, 0.3)"
                                        }}
                                    />
                                </div>
                            </div>
                        </InnerContainer>
                    </div>
                )
            })}
        </div>
    )
}

export default Plataformas
