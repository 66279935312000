import React, { useState, useEffect } from "react"
import styles from "./headlines.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Menu from "./menu"

const Headlines = () => {
    const data = useStaticQuery(graphql`
        query HeadlinesQuery {
            allMarkdownRemark(filter: { fields: { sourceInstanceName: { eq: "headlines" } } }) {
                edges {
                    node {
                        frontmatter {
                            pretitle
                            title
                            description
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 1800, quality: 100) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            }
            logoLight: file(absolutePath: { regex: "/logoLight.png/" }) {
                childImageSharp {
                    fixed(width: 400, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % data.allMarkdownRemark.edges.length)
        }, 10000)
        return () => clearTimeout(timer)
    })

    return (
        <div
            className={styles.headlineWrap}
            style={{
                backgroundImage: `url(${data.allMarkdownRemark.edges[currentIndex].node.frontmatter.image.childImageSharp.fluid.src})`
            }}
        >
            <div className={styles.logoWrap}>
                <Image
                    fixed={data.logoLight.childImageSharp.fixed}
                    style={{
                        maxWidth: `100%`
                    }}
                    imgStyle={{
                        width: `100%`,
                        height: `auto`
                    }}
                />
            </div>
            <div className={styles.menuWrap}>
                <div className={styles.menu}>
                    <Menu isHorizontal={false} light={true} />
                </div>
            </div>
            {data.allMarkdownRemark.edges.map((headline, index) => {
                if (index === currentIndex) {
                    return (
                        <div key={index} className={index === currentIndex ? styles.headline : styles.hiddenHeadline}>
                            <div className={styles.position}>
                                <div className={styles.wrap}>
                                    <p className={styles.pretitle}>{headline.node.frontmatter.pretitle}</p>
                                    <h1 className={styles.title}>{headline.node.frontmatter.title}</h1>
                                    <p className={styles.description}>{headline.node.frontmatter.description}</p>
                                </div>

                                <div className={styles.circulos}>
                                    {data.allMarkdownRemark.edges.map((_, index) => {
                                        return (
                                            <button
                                                key={`boton-mover-${index}`}
                                                className={
                                                    index == currentIndex
                                                        ? `${styles.circulo} ${styles.selected_circulo}`
                                                        : styles.circulo
                                                }
                                                onClick={() => setCurrentIndex(index)}
                                            ></button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return <div key={index}></div>
                }
            })}
        </div>
    )
}

export default Headlines
