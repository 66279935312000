import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InnerContainer from "./innerContainer"
import styles from "./newsletter.module.css"

const Newsletter = () => {
    const data = useStaticQuery(graphql`
        query NewsletterQuery {
            background: file(absolutePath: { regex: "/fondo-newsletter.png/" }) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                        src
                    }
                }
            }
        }
    `)

    return (
        <div
            className={styles.newsletter}
            style={{ backgroundImage: `url(${data.background.childImageSharp.fluid.src})` }}
        >
            <InnerContainer>
                <h2>Suscríbete a nuestro Newsletter</h2>
                <div className={styles.customBottomRow}>
                    <input
                        className={styles.customInput}
                        type="text"
                        name="name"
                        id="newsletter"
                        placeholder="Ingresa tu email"
                    />
                    <button type="button" className={styles.subscribe}>
                        Subscribirse
                    </button>
                </div>
            </InnerContainer>
        </div>
    )
}

export default Newsletter
