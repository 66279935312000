import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InnerContainer from "./innerContainer"
import styles from "./trabajaConNosotros.module.css"

const TrabajaConNosotros = () => {
    const data = useStaticQuery(graphql`
        query TrabajaConNosotrosQuery {
            background: file(absolutePath: { regex: "/fondo-trabaja.png/" }) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                        src
                    }
                }
            }
        }
    `)

    return (
        <div
            className={styles.trabaja}
            style={{ backgroundImage: `url(${data.background.childImageSharp.fluid.src})` }}
        >
            <InnerContainer>
                <h2>Trabaja con nosotros</h2>
                <p className={styles.info}>Buscamos a los mejores profesionales para unirse a nuestro equipo.</p>
                <div className={styles.customRow}>
                    <p className={"styled-link"}>Contáctanos al correo</p>
                    <p className={styles.value}>
                        <a href="mailto:procesosdeseleccion@ogr.cl">procesosdeseleccion@ogr.cl</a>
                    </p>
                </div>
                <div className={styles.customRow}>
                    <p className={"styled-link"}>Si prefieres al teléfono</p>
                    <p className={styles.value}>
                        <a href="tel:+56223069220">(+56) 2 2306 9220</a>
                    </p>
                </div>
            </InnerContainer>
        </div>
    )
}

export default TrabajaConNosotros
