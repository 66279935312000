import React from "react"
import InnerContainer from "./innerContainer"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const VideoWrap = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    max-width: 100%;
    margin-top: -50px;
`
const VideoIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const VideoCorporativo = () => {
    const data = useStaticQuery(graphql`
        query VideoQuery {
            site {
                siteMetadata {
                    videoCorporativo
                }
            }
        }
    `)
    const { videoCorporativo } = data.site.siteMetadata

    return (
        <InnerContainer noPadding={true}>
            <VideoWrap>
                <VideoIframe
                    src={videoCorporativo}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                />
            </VideoWrap>
        </InnerContainer>
    )
}

export default VideoCorporativo
