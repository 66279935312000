import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./arrows.module.css"

function CustomLeftArrow(props) {
    const data = useStaticQuery(graphql`
        query ArrowLQuery {
            arrow: file(absolutePath: { regex: "/arrowLeft.png/" }) {
                childImageSharp {
                    fixed(height: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const { className, style, onClick } = props
    return (
        <button
            className={`${className} ${styles.arrow}`}
            style={{ ...style, display: "block", background: "transparent", height: "30px" }}
            onClick={onClick}
        >
            <Img fixed={data.arrow.childImageSharp.fixed} alt="arrow_left" />
        </button>
    )
}

export default CustomLeftArrow
