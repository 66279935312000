import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InnerContainer from "./innerContainer"
import styled from "styled-components"
import Img from "gatsby-image"
import Image from "gatsby-image"
import Slider from "react-slick"
import CustomLeftArrow from "./customLeftArrow"
import CustomRightArrow from "./customRightArrow"

const ContentWrap = styled.div`
    background-color: #f2f2f2;
    padding: 3.2rem 30px;
    @media screen and (max-width: 800px) {
        padding: 3.2rem 0;
    }
`

const TestimonioWrap = styled.div`
    padding: 30px;
    @media screen and (max-width: 800px) {
        padding: 10px;
    }
`
const Testimonio = styled.div`
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 40px 30px 40px 60px;
    position: relative;
    @media screen and (max-width: 800px) {
        padding: 20px 20px 20px 30px;
    }
`
const Comillas = styled.div`
    position: absolute;
    left: 12px;
    top: 12px;
    @media screen and (max-width: 800px) {
        left: 0;
        top: 0;
        transform: scale(0.6);
    }
`

const TestimonioBottom = styled.div`
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    align-items: center;
`
const Nombre = styled.div`
    display: flex;
    font-weight: 600;
    line-height: 1.2rem;
    font-size: 0.9rem;
`
const Posicion = styled.div`
    display: flex;
    line-height: 1.2rem;
    font-size: 0.9rem;
`
const Logo = styled.div`
    display: flex;
    margin-left: 15px;
`

const JustifiedAligned = styled.div`
    text-align: justify;
`

const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
            }
        }
    ]
}

const Testimonios = () => {
    const data = useStaticQuery(graphql`
        query TestimoniesQuery {
            allMarkdownRemark(
                filter: { fields: { sourceInstanceName: { eq: "testimonies" } } }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                edges {
                    node {
                        html
                        frontmatter {
                            title
                            name
                            position
                            logo {
                                childImageSharp {
                                    fixed(width: 100, quality: 100) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }

            comillas: file(absolutePath: { regex: "/icono-testimonios.png/" }) {
                childImageSharp {
                    fixed(width: 30, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)
    return (
        <ContentWrap>
            <InnerContainer>
                <Slider {...settings}>
                    {data.allMarkdownRemark.edges.map((testimonio, index) => {
                        return (
                            <TestimonioWrap key={index}>
                                <Testimonio>
                                    <Comillas>
                                        <Image
                                            fixed={data.comillas.childImageSharp.fixed}
                                            style={{
                                                maxWidth: "100%"
                                            }}
                                            imgStyle={{
                                                width: "100%",
                                                height: "auto"
                                            }}
                                        />
                                    </Comillas>
                                    <JustifiedAligned dangerouslySetInnerHTML={{ __html: testimonio.node.html }} />
                                    <TestimonioBottom>
                                        <div>
                                            <Nombre>{testimonio.node.frontmatter.name}</Nombre>
                                            {testimonio.node.frontmatter.position.split(/,\s*/).map((position, j) => (
                                                <Posicion key={`position-${index}-${j}`}>{position}</Posicion>
                                            ))}
                                        </div>
                                        <Logo>
                                            <Img
                                                key={index}
                                                fixed={testimonio.node.frontmatter.logo.childImageSharp.fixed}
                                                style={{ maxHeight: "50px" }}
                                                imgStyle={{
                                                    objectFit: "contain",
                                                    objectPosition: "center"
                                                }}
                                            />
                                        </Logo>
                                    </TestimonioBottom>
                                </Testimonio>
                            </TestimonioWrap>
                        )
                    })}
                </Slider>
            </InnerContainer>
        </ContentWrap>
    )
}

export default Testimonios

/*{data.allMarkdownRemark.edges.map((testimonio, index) => {
                    return <Testimonio key={index}>{testimonio.node.html}</Testimonio>
                })}*/
